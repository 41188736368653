.joinUs {
  padding-bottom: 20px;
  margin-top: -15px;

  position: relative;
  z-index: 1;
}
.copy {
  position: relative;
  cursor: pointer;
}
.copy::before {
  content: "Copied";
  padding: 5px 10px;
  border-radius: 4px;
  font-family: "Inter";
  font-weight: 400;
  color: #fdaf27;
  background-color: #000;
  border: 1px solid #fdaf27;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.join {
  background: #000000;
  border: 1px solid #fdaf27;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #fdaf27;
  border: 1px solid #fdaf27;
  padding: 20px 12px;
}
.boxWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 30px;
  height: 100%;
  padding: 15px 0;
}
.box {
  background: #000000;
  border: 1px solid #fdaf27;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 130%;
  color: #fdaf27;
  padding-bottom: 10px;
}
.text {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 130%;
  color: #fdaf27;
  padding: 8px;
  gap: 5px;
}
.link {
  color: #fdaf27;
}
.list {
  padding: 0;
  display: flex;
}
.circle {
  width: 5px;
  height: 5px;
  background-color: #fdaf27;
  border-radius: 50%;
  margin-top: 8px;
}
.button {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
  background: #fdaf27;
  border-radius: 10px;
  padding: 7px 40px;
  margin-top: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.8s;
  border: 1px solid #fdaf27;
}
.button:hover {
  color: #fdaf27;
  background: #000;
  border: 1px solid #fdaf27;
}
@media only screen and (max-width: 767px) {
  .boxWrapper {
    grid-template-columns: 1fr;
  }
  .join {
    font-size: 24px;
  }
  .title {
    font-size: 20px;
  }
  .text {
    font-size: 15px;
  }
}
