.wrapper {
  width: 95%;
  margin: 0 auto;
  padding: 15px 0;
  padding-top: 50px;
}
.heading {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 125%;

  color: #fdaf27;
  padding: 0 20px;
}
.sliderWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px;
  position: relative;
}
.image {
  width: 100%;
  object-fit: cover;
}
.discount {
  position: absolute;
  top: -3.5%;
  left: 0.2%;
}
.textContainer {
  padding: 20px 0;
  padding-top: 16px;
}
.title {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 130%;
  color: #fdaf27;
  padding-bottom: 5px;
}
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.2em;
  color: #fdaf27;
}
.button {
  display: block;
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
  background: #fdaf27;
  border-radius: 10px;
  padding: 7px 40px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.8s;
  border: 1px solid #fdaf27;
}
.button:hover {
  color: #fdaf27;
  background: #000;
  border: 1px solid #fdaf27;
}
@media only screen and (max-width: 1199px) {
  .discount {
    top: -4%;
    left: -1.5%;
  }
}
@media only screen and (max-width: 991px) {
  .heading {
    font-size: 28px;
  }
  .title {
    font-size: 20px;
  }
  .button {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .sliderWrapper {
  }
  .discount {
    top: -3%;
    left: -1%;
    z-index: 100;
  }
}
@media only screen and (max-width: 520px) {
  .discount {
    top: -3%;
    left: -1%;

    width: 70px;
  }
}
@media only screen and (max-width: 450px) {
  .sliderWrapper {
    padding: 10px 10px;
  }
  .heading {
    padding: 0 10px;
  }
  .discount {
    top: -4.5%;
    left: -3.5%;

    width: 60px;
  }
}
