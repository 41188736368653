.buttonContainer {
  display: flex;
  justify-content: flex-end;

  padding: 15px 0;
}
.button {
  display: flex;
  align-items: center;
  background: #fdaf27;
  border-radius: 10px;
  padding: 15px 15px;
  gap: 7px;
}
.circle {
  width: 18px;
  height: 18px;
  background: #15ed44;
  border-radius: 50%;
}
.text {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 115%;

  color: #000000;
}
@media only screen and (max-width: 1199px) {
  .button {
    padding: 10px 15px;
    gap: 6px;
  }
  .text {
    font-size: 20px;
  }
  .circle {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (max-width: 520px) {
  .text {
    font-size: 18px;
  }
  .circle {
    width: 15px;
    height: 15px;
  }
}
