.header {
  background: #000000;
  border: 1px solid #fdaf27;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #fdaf27;
  border: 1px solid #fdaf27;
  padding: 20px 12px;
  margin-top: -15px;
  position: relative;
  z-index: 1;
}
.wrapper {
  background: #000000;
  border: 1px solid #fdaf27;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  margin: 25px 0;
}
.image {
  max-width: 300px;
  width: 90%;
  margin: 0 auto;
  display: block;
}
.faqContainer {
  padding: 30px 0;
}
@media only screen and (max-width: 767px) {
  .header {
    font-size: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 24px 8px;
  }
}
