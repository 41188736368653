.faq {
  padding: 8px 0;
}
.questionContainer {
  display: grid;
  grid-template-columns: 1fr 20px;
  background: #000000;
  border: 1px solid #fdaf27;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 24px;
  gap: 15px;
}
.question {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 125%;
  color: #fdaf27;

  display: flex;
  justify-content: start;
}
.answer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #fdaf27;
  background: #000000;
  border: 1px solid #fdaf27;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 24px;
}

.icon {
  color: #fdaf27;
  cursor: pointer;
  font-size: 30px;
  line-height: 125%;
}
@media only screen and (max-width: 991px) {
  .question {
    font-size: 24px;
  }
  .answer {
    font-size: 20px;
  }
  .icon {
    font-size: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .questionContainer {
    padding: 20px 12px;
  }
  .question {
    font-size: 20px;
  }
  .answer {
    padding: 20px 12px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 420px) {
  .question {
    font-size: 18px;
  }
}
