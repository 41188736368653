.aboutUsWrapper {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 4px solid #fdaf27;
}

.aboutUs {
  background: #000000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #fdaf27;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: grid;
  grid-template-columns: 0.7fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 35px 40px;
}
.textContainer {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  gap: 50px;
}
.image {
  width: 100%;
  margin: 0 auto;
}
.wrapper {
  display: flex;
  justify-content: center;
}
.title {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #fdaf27;
  padding-bottom: 10px;
}

.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 130%;
  color: #fdaf27;
}
.list {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}
.circle {
  width: 7px;
  height: 7px;
  background: #fdaf27;
  border-radius: 50%;
}
.listItem {
  font-weight: 600;
}
.copyRight {
  background: #fdaf27;
  padding: 18px;
}
.copyRightText {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 115%;
  color: #4f1164;

  text-align: center;
}
@media only screen and (max-width: 1399px) {
  br {
    display: none;
  }
}
@media only screen and (max-width: 1199px) {
  .aboutUs {
    grid-template-columns: 1fr;
  }
  .image {
    width: 400px;
  }
}
@media only screen and (max-width: 991px) {
  .title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .textContainer {
    grid-template-columns: 1fr;
  }
  .wrapper {
    justify-content: start;
  }
}
@media only screen and (max-width: 575px) {
  .image {
    width: 90%;
  }
}
