.bold {
  font-weight: 700;
}
.slick-arrow {
  top: 35%;
  z-index: 1;
}
.slick-arrow:before {
  color: #fdaf27;
  font-size: 40px;
  opacity: 1;
}
.slick-prev {
  left: -30px;
}
.slick-next {
  right: -18px;
}
@media only screen and (max-width: 991px) {
  .slick-arrow:before {
    font-size: 30px;
  }
}
@media only screen and (max-width: 520px) {
  .slick-arrow {
    top: 28%;
    z-index: 1;
  }
  .slick-arrow:before {
    font-size: 21px;
  }
  .slick-prev {
    left: -20px;
  }
}
